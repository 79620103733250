<template>
  <div class="simple-brand">
    <header>
      <nav>
        <nuxt-link to="/">
          <h1>orienteer</h1>
        </nuxt-link>
      </nav>
    </header>
    <NuxtPage />
  </div>
</template>
<style scoped>
.simple-brand {
  padding-top: calc(var(--grid) * 3);
}

.simple-brand header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: calc(var(--grid) * 3);
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--header-shadow);
}

header h1 {
  color: var(--brand);
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: -2px;
  line-height: calc(var(--grid) * 3);
}
</style>
